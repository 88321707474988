import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { EmployeeAPI } from "api/KPIAPI";
import Alert from "components/Alert";
import { InputNumber } from "components/FormInputType";
import KPICiteriaModal from "components/Modal/KPICiteriaModal";
import { IScoreEmployeeData, IScorePercent } from "models/kpi.model";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { selectAuthen } from "redux/authen/selector";
import { selectDDLUserGroup } from "redux/dropdown/reducer";
import { useAppSelector } from "redux/hooks";
import { HasPermission } from "utils/auth.util";
import { score_default } from "variable/scoreInfo";
import { Footer } from "../../../components/Item/ItemKPI";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { roundTwoDigit } from "../../../utils";
import ExportModal from "./export_modal";
import List from "./list";

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const [Items, setItems] = useState<Array<IScoreEmployeeData>>([]);
  const [scorePercent, setScorePercent] = useState<Array<IScorePercent>>([]);
  const [searchEmp, setSearchEmp] = useState<string>("");
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [editPercent, setEditPercent] = useState<boolean>(false);
  const [Textloading, setTextLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState(0);
  const userGroupOption = useAppSelector(selectDDLUserGroup);
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const history = useHistory();
  const [alert, setAlert] = useState<boolean>(false);
  const [textAlert, setTextAlert] = useState<string>("");
  const { user } = useAppSelector(selectAuthen);

  const {
    state: { period },
  } = useKPI();

  const { permission } = useAppSelector(selectAuthen);

  const calDirectorAcm = useCallback(
    (data: any, scorePercent: IScorePercent[]) => {
      const final_score =
        (data.vp_acm * getScorePercent("vp_acm", scorePercent) +
          data.e360_acm * getScorePercent("e360_acm", scorePercent)) /
        100.0;
      return roundTwoDigit(final_score);
    },
    []
  );

  useEffect(() => {
    setTextLoading(true);
    if (period) {
      EmployeeAPI.read({ period: period.name, group: userGroup })
        .then((res) => {
          const { score_list, score_percent } = res.data;
          /// get score percent
          let percentList =
            score_percent.length > 0
              ? score_default.map((data) => ({
                  ...data,
                  percent: getScorePercent(data.acm_name, score_percent),
                }))
              : score_default;
          setScorePercent(percentList);
          /// get score emp
          setItems(
            score_list.map((data: any) => ({
              ...data,
              director_acm: data.director_acm
                ? data.director_acm
                : calDirectorAcm(data, percentList),
            }))
          );
        })
        .then(() => setTextLoading(false));
    } else history.replace(path);
  }, [period, userGroup, update, history, path, calDirectorAcm]);

  const countFilled = () => {
    const allfilled = Items.filter(
      (data) => data.director_acm !== 0 && data.director_acm !== null
    );
    return { filled: allfilled.length, all: Items.length };
  };

  const updateScore = (event: any) => {
    event.preventDefault();

    const data = Items.map((value) => {
      return {
        ...value,
        diff_ratio: value.director_acm / calDirectorAcm(value, scorePercent),
      };
    });

    setShowLoading(true);
    setTextLoading(true);
    EmployeeAPI.update(data, { period: period!.name })
      .then((item) => {
        if (Array.isArray(item.data)) {
        } else {
          console.log("failure");
        }
        setShowLoading(false);
        setTextLoading(false);
        setUpdate(update + 1);
        setSaveSuccess(true);
      })
      .catch((err) => {
        console.log("failure");
        setShowLoading(false);
        setTextLoading(false);
      });
  };

  const getScorePercent = (
    acm_name: string,
    scorePercent: Array<IScorePercent>
  ): number => {
    const percent = scorePercent.find((value) => value.acm_name === acm_name);
    return percent ? percent.percent : 0;
  };

  const validatePercent = () => {
    let sumPercent = 0;
    let invalid = false;
    scorePercent.forEach((value) => {
      sumPercent += value.percent;
      if (value.percent > 100 || value.percent < 0) invalid = true;
    });
    if (invalid) {
      setTextAlert("Percent is incorrect");
      return false;
    } else if (sumPercent !== 100) {
      setTextAlert("Percentage summary is incorrect");
      return false;
    } else return true;
  };

  const updatePercent = (event: any) => {
    event.preventDefault();
    if (editPercent) {
      setTextLoading(true);

      if (validatePercent()) {
        const data = Items.map((value) => {
          return {
            ...value,
            director_acm: roundTwoDigit(
              calDirectorAcm(value, scorePercent) * value.diff_ratio
            ),
          };
        });
        EmployeeAPI.updatePercent(
          { scorePercent, data },
          { period: period!.name }
        )
          .then((item) => {
            setTextLoading(false);
            setUpdate(update + 1);
            setSaveSuccess(true);
          })
          .catch((err) => {
            console.log("failure");
            setAlert(true);
            setTextAlert("Unable to save. Please try again!!");
            setTextLoading(false);
          });
        setEditPercent(false);
      } else {
        setAlert(true);
        setTextLoading(false);
      }
    } else setEditPercent(true);
    return 0;
  };

  const onChangePercent = (acm_name: string, data: any) => {
    let index = scorePercent.findIndex(
      (scorePercent) => acm_name === scorePercent.acm_name
    );
    if (index !== -1) {
      setScorePercent([
        ...scorePercent.slice(0, index),
        { ...scorePercent[index], percent: data },
        ...scorePercent.slice(index + 1),
      ]);
    }
  };

  const filterSearch = (list: Array<IScoreEmployeeData>, searchEmp: string) =>
    list.filter((emp) => emp.emp_id.indexOf(searchEmp.toLowerCase()) > -1);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/kpi/emp" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeSm="12">
                <IonItem lines="none">
                  <IonSearchbar
                    placeholder="Search Employee"
                    value={searchEmp}
                    onIonChange={(e) => {
                      setSearchEmp(e.detail.value!);
                    }}
                  ></IonSearchbar>
                </IonItem>
              </IonCol>
              {/* <IonCol size="12" sizeSm="6">
                <IonItem>
                  <IonLabel>Employee Group</IonLabel>
                  <IonSelect
                    value={userGroup}
                    onIonChange={(e) => setUserGroup(e.detail.value)}
                    interface="popover"
                  >
                    {userGroupOption.map(({ label, value }) => (
                      <IonSelectOption value={value} key={label}>
                        {label}
                      </IonSelectOption>
                    ))}
                    <IonSelectOption value={null} key="All">
                      All
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol> */}
            </IonRow>
          </IonGrid>
        </IonToolbar>
        {/* <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeSm="12">
                <IonItem lines="none">
                  <IonLabel>Score Percentage</IonLabel>
                  <IonGrid>
                    <IonRow>
                      {scorePercent.map((value, i) => (
                        <IonCol key={i}>
                          <InputNumber
                            label={`${value.acm_fullname} (%)`}
                            value={value.percent}
                            onChange={(e: any) =>
                              onChangePercent(value.acm_name, e.target.value!)
                            }
                            max={100}
                            min={0}
                            readonly={!editPercent}
                          ></InputNumber>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                  {HasPermission(
                    permission,
                    "employee_kpi",
                    "edit-percent"
                  ) && (
                    <IonButtons slot="end">
                      <IonButton
                        fill="solid"
                        color="primary"
                        onClick={(e) => {
                          updatePercent(e);
                        }}
                      >
                        {editPercent ? "Update" : "Edit"}
                      </IonButton>
                    </IonButtons>
                  )}
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar> */}
      </IonHeader>
      <IonContent>
        {Textloading ? (
          <Spinning />
        ) : (
          <div>
            {Boolean(Items.length) ? (
              <List
                Items={filterSearch(Items, searchEmp)}
                ScoreList={Items}
                setScoreList={setItems}
                countFilled={countFilled}
                period={period}
              />
            ) : (
              <h1 className="ion-text-center">No Record</h1>
            )}
          </div>
        )}
      </IonContent>

      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Saving..."}
      />
      {Boolean(Items.length) && (
        <Footer
          filledCount={countFilled()}
          updateScore={updateScore}
          editable={period?.edit_empKPI&& user && user.role.includes("dr")}
          buttonList={[<ExportModal />, <KPICiteriaModal />]}
        />
      )}
      <Alert
        showAlert={alert}
        onDisDismiss={() => {
          setAlert(false);
        }}
        message={textAlert}
      />
    </IonPage>
  );
};

export default Page;
