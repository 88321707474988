import {
  IonAccordion,
  IonAccordionGroup,
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import React, { useState } from "react";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import { IPeriod } from "../../../models/kpi.model";
import { getEmpFullName, getWorkingTime, roundTwoDigit } from "../../../utils";
import ModalForm from "./modal";
import { IScoreDepartmentData } from "models/kpi.model";
import { selectAuthen } from "redux/authen/selector";
import { LeaveStorypointChip, StorypointChip } from "components/Chip";
import { alertCircle, checkmarkCircle } from "ionicons/icons";
import { selectDimensionsList } from "redux/dropdown/reducer";

const List: React.FC<{
  Items: Array<IScoreDepartmentData>;
  ScoreList: Array<IScoreDepartmentData>;
  setScoreList: Function;
  countFilled: Function;
  period: IPeriod | null;
  finalScores: Array<number>;
}> = ({ Items, ScoreList, setScoreList, countFilled, period, finalScores }) => {
  const empList = useAppSelector(selectEmpList);
  const { user } = useAppSelector(selectAuthen);

  const changeData = (
    data: Array<IScoreDepartmentData>,
    detail: IScoreDepartmentData
  ) => {
    let index = ScoreList.findIndex(
      (score: IScoreDepartmentData) => score.emp_id === detail.emp_id
    );
    if (index !== -1) {
      setScoreList([
        ...ScoreList.slice(0, index),
        { ...data },
        ...ScoreList.slice(index + 1),
      ]);
    }
  };

  const dimensionList = useAppSelector(selectDimensionsList);

  const renderKPIList = Items.map((p, i) => {
    const finalScore = finalScores[i];
    return (
      <IonAccordion key={i}>
        <IonItem
          slot="header"
          color="light"
          lines="full"
          className="ion-align-items-center"
        >
          <IonButtons slot="start">
            <EmpChip value={p.emp_id} />
          </IonButtons>
          <IonText style={{ fontWeight: "bold" }}>
            {`${getEmpFullName(p.emp_id, empList)}`}
          </IonText>
          <IonChip>{`${getWorkingTime(p.emp_id, empList)}`}</IonChip>
          {Boolean(
            p.vp_acm !== null &&
              p.di2_acm !== null &&
              p.di3_acm !== null &&
              p.di4_acm !== null &&
              p.di5_acm !== null &&
              p.di6_acm !== null
          ) ? (
            <IonIcon
              icon={checkmarkCircle}
              color="success"
              slot="end"
            ></IonIcon>
          ) : (
            <IonIcon icon={alertCircle} color="alert" slot="end"></IonIcon>
          )}
          <IonButtons slot="end">
            <ModalForm emp_id={p.emp_id} />
          </IonButtons>
        </IonItem>
        <div className="ion-padding" slot="content">
          <IonGrid className="ion-no-margin ion-no-padding">
            <IonRow style={{ paddingBottom: "5px" }}>
              <IonCol size="12" size-sm="3">
                <p>
                  <StorypointChip value={p.total_storypoint} slot={"start"} />
                  <LeaveStorypointChip
                    value={p.leave_storypoint}
                    slot={"start"}
                  />
                </p>
                <IonItem lines="none">
                  <IonLabel position="fixed">{"Tier-1 Score : "}</IonLabel>
                  {p.pm_acm ? roundTwoDigit(p.pm_acm) : "N/A"}
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">{"KPI ฝ่าย : "}</IonLabel>
                  {p.kpi_acm ? roundTwoDigit(p.kpi_acm) : "N/A"}
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">{"Peer review : "}</IonLabel>
                  {p.e360_acm ? roundTwoDigit(p.e360_acm) : "N/A"}
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">{"PIP Score : "}</IonLabel>
                  {p.pip_acm ? roundTwoDigit(p.pip_acm) : "N/A"}
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">{"PMS Score : "}</IonLabel>
                  {p.pms_acm ? roundTwoDigit(p.pms_acm) : "N/A"}
                </IonItem>
              </IonCol>
              <IonCol size="12" size-sm="9">
                <ScoreForm
                  data={dimensionList}
                  p={p}
                  changeData={changeData}
                  editable={Boolean(
                    period?.edit_department && user && user.role.includes("vp")
                  )}
                  finalScore={finalScore}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonAccordion>
    );
  });

  return (
    <div className="ion-align-self-center">
      {Boolean(Items.length) ? (
        <IonAccordionGroup multiple>{renderKPIList}</IonAccordionGroup>
      ) : (
        <h1 className="ion-text-center">No Record</h1>
      )}
    </div>
  );
};

const ScoreForm: React.FC<{
  data: any;
  p: IScoreDepartmentData;
  changeData: Function;
  editable: boolean;
  finalScore: number;
}> = ({ data, p, changeData, editable, finalScore }) => {
  const [isEdit, setIsEdit] = useState("dark");

  const checkEditable = () => {
    if (!editable) return true;
    else return false;
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-sm="10">
            <IonItem lines="none" color="medium">
              <IonLabel position="fixed" class="ion-text-wrap">
                {"Final Score "}
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-sm="2">
            <IonItem lines="none" color="medium">
              <IonLabel position="fixed" class="ion-text-wrap">
                {finalScore}
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" size-sm="10">
            <IonItem lines="inset">
              <IonLabel>{"Excellence (Tier-2 Score)"}</IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-sm="2">
            <IonItem lines="inset">
              <IonInput
                type="number"
                name="vp_acm"
                id="vp_acm"
                max="5"
                min="0"
                step="0.5"
                value={p.vp_acm}
                color={isEdit}
                disabled={checkEditable()}
                onIonChange={(e) => {
                  let score = parseFloat(e.detail.value!);
                  if (score && (score > 5 || score < 0)) {
                    setIsEdit("danger");
                  }
                }}
                onIonBlur={(e: any) => {
                  let score = parseFloat(e.target.value!);
                  if (score && (score > 5 || score < 0)) {
                    //setDirectorACM(0);
                    setIsEdit("danger");
                    changeData({ ...p, vp_acm: 0 }, p);
                  } else {
                    //setDirectorACM(score);
                    setIsEdit("dark");
                    changeData({ ...p, vp_acm: score ? score : null }, p);
                  }
                }}
                onWheel={(e: any) => {
                  e.preventDefault();
                  e.target.blur();
                }}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        {data.map((item, index) => {
          return (
            <IonRow key={item.id}>
              <IonCol size="12" size-sm="10">
                <IonItem lines="inset">
                  <IonLabel class="ion-text-wrap">
                    {item.name}
                    {/* <p>{item.th}</p> */}
                  </IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="12" size-sm="2">
                <IonItem lines="inset">
                  <IonInput
                    type="number"
                    name={item.id}
                    id={item.id}
                    max="5"
                    min="0"
                    step="1"
                    value={p[item.id]}
                    color={isEdit}
                    disabled={checkEditable()}
                    onIonChange={(e) => {
                      let score = parseFloat(e.detail.value!);
                      if (score && (score > 5 || score < 0)) {
                        setIsEdit("danger");
                      }
                    }}
                    onIonBlur={(e: any) => {
                      let score = parseFloat(e.target.value!);
                      if (score && (score > 5 || score < 0)) {
                        //setDirectorACM(0);
                        setIsEdit("danger");
                        changeData({ ...p, [item.id]: 0 }, p);
                      } else {
                        //setDirectorACM(score);
                        setIsEdit("dark");
                        changeData(
                          { ...p, [item.id]: score ? score : null },
                          p
                        );
                      }
                    }}
                    onWheel={(e: any) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          );
        })}
        <IonRow>
          <IonCol size="12">
            <IonItem lines="inset">
              <IonLabel style={{ flexBasis: "200px" }} position="fixed">
                {"Adjustment Reason"}
              </IonLabel>
              <IonTextarea
                autoGrow
                value={p.comment}
                disabled={checkEditable()}
                onIonBlur={(e: any) => {
                  changeData({ ...p, comment: e.target.value! }, p);
                }}
              ></IonTextarea>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default List;
